<template>
  <div class="" v-if='!formLoading'>
    <b-card v-if='!loading && !formLoading'>
      <h4>
        Stream Settings
      </h4>
      <b-row class="py-2 px-1" >
        <b-col>
          <ResponsiveFieldsForm 
            :defaultItem="defaultItem" 
            :editObj='formObj' 
            :getRestData='getRestData' 
            :formFields="restFields" 
            ref='restForm' 
          />
        </b-col>
      </b-row>
      <b-button 
        variant='success'
        @click='formSubmit'
        v-if='streamType == "audio" ? resolvePermission("audioStream.edit") : resolvePermission("videoStram.edit")'
      >
        {{$t('submit')}}
      </b-button>
    </b-card>
    
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from '@core/components/forms/ResponsiveFieldsForm.vue';
// import BaseForm from './BaseForm.vue';
// import ProductRestForm from './ProductRestForm.vue';
// import ImgForm from './ImgForm.vue';

import {
  BRow, BCol, BCard, BButton
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    // BaseForm,
    // ProductRestForm,
    // ImgForm,
    BRow, BCol, BCard, BButton
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        // color: '#FFFFFF',
        status: true,
      }
    }
  },
  watch: {
    $route: function() {
      if(this.isAudioStream == true) {
        this.formObj = {};
        this.formLoading = true;
        this.getAudioStream() 
          .then(res => {
            setTimeout(() => {
              this.formObj = res.data.results;
              this.formLoading = false;
              this.$forceUpdate()
            }, 100)
          })
      }else {
        this.formLoading = true;
        this.formObj = {};
        this.getVideoStream() 
          .then(res => {
            setTimeout(() => {
              this.formObj = res.data.results;
              this.formLoading = false;
              this.$forceUpdate()

            }, 100)
          })
      }
    }
  },
  props: ['editObj', 'closeModal',],
  mounted() {
      this.formLoading = true
      if(this.isAudioStream == true) {
        this.getAudioStream() 
          .then(res => {
            this.formObj = res.data.results;
            this.formData.id = 1;
            this.formLoading = false;
          }).catch(err => {

          })
      }else {
        this.getVideoStream() 
          .then(res => {
            this.formObj = res.data.results;
            this.formData.id = 1;
            this.formLoading = false;
          }).catch(err => {
            
          })
      }
  },
  methods: {
    ...mapActions(['getAudioStream', 'getVideoStream', 'createVideoStream', 'createAudioStream']),
    formSubmit()  {
      this.$refs.restForm.submit()
        .then(res => {
          if (res == true) {
            if(this.isAudioStream) {
              console.log(this.formData)
              this.createAudioStream(this.formData)
              .then(res => {
                
              })
            }else {
              this.createVideoStream(this.formData) 
                .then(res => {
                  
                  })
            }
        }
      })

      // this.$refs.restForm.submit()
      //   .then(res => {
      //     if(res == true) {
      //       if(res == true) {
      //         this.$store.commit('setModalLoading', true);
      //         this.$refs.restForm.submit();
      //         // console.log(this.formData)
      //         setTimeout(() => {
      //           if(this.isAudioStream == true) {
      //             this.createAudioStream(this.formData)
      //               .then(res => {
      //                 this.closeModal();
      //                 this.getAudioStream({});
      //                 this.$store.commit('setModalLoading', false);
      //               }) 
      //           }else {
      //             this.createVideoStream(this.formData)
      //               .then(res => {
      //                 this.closeModal();
      //                 this.getVideoStream({});
      //                 this.$store.commit('setModalLoading', false);
      //               })
      //           }
      //           }, 100)
      //       }
      //     }
      //   })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      console.log(data)
      this.formData.base64 = [];
      if(this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true
          }
        ]
      }
        
      this.formData.base64 = [
        ...this.formData.base64,
        data,
      ];
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar == null || this.formData.descriptions.en == null) {
        if(this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = ''
        }
        if(this.formData.descriptions.en == null) {
          this.formData.descriptions.en = ''
        }
      }
    }
  },

  computed: {
    ...mapGetters(['videoStream', 'audioStream', 'modalLoading']),
    restFields() {
      return [
        {name: 'Type', type: 'select', options: this.isAudioStream ? this.audioStreamItems : this.videoStreamItems, rules: 'required', model: 'type', },
        {name: 'URL', type: 'text', rules: 'required', model: 'url' },
        // {name: 'ord', type: 'number', rules: 'required', model: 'ord' },
        {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
    },
    streamType() {
      return this.$route.name == 'AudioStream' ? 'audio' : 'video'; 
    },
    isAudioStream() {
      return this.$route.name == 'AudioStream';
    },
    audioStreamItems() {
      return [
        'ShoutCast',
        'IceCast'
      ]
    },
    videoStreamItems() {
      return [
        'RTMP',
        'Vimeo',
        'YouTube',
        'instagram',
        'dailymotion',
      ]
    }
  }

}
</script>